import React from 'react'
import './App.css'
import Home from './components/Home'

const App: React.FC = () => {
  return (
    <div className='App'>
      <Home />
    </div>
  )
}

export default App
