import React from 'react'

import styles from './About.module.css'

const About: React.FC = () => {
  return (
    <div id='about' className={styles.About}>
      <h1>
        Hello, I'm <span>Arturo Dominguez</span>.
        <br />
        I'm a full-stack web developer.
      </h1>

      <p>
        I have 10+ years of experience creating amazing apps using technologies
        like:
      </p>

      <div className={styles.Tech}>
        <div>JavaScript</div>
        <div>TypeScript</div>
        <div>React</div>
        <div>Node.js</div>
        <div>Express</div>
        <div>GraphQL</div>
        <div>Python</div>
        <div>Flask</div>
        <div>SQL Databases</div>
        <div>No-SQL Databases</div>
        <div>AWS</div>
        <div>Google Cloud Platform</div>
        <div>Azure</div>
        <div>Firebase</div>
        <div>Docker</div>
      </div>
    </div>
  )
}

export default About
