import React from 'react'

import styles from './Work.module.css'

const Work: React.FC = () => {
  return (
    <div id='work' className={styles.Work}>
      <h1>Work</h1>

      <div className={styles.Timeline}>
        <div className={styles.Year}>2022</div>

        <h2>Senior Software Engineer at Remotion (remotion.com)</h2>
        <h3>April 2021 - Present</h3>
        <ul>
          <li>Implemented a new onboarding flow</li>
          <li>Created a synchronized music sharing experience using Youtube</li>
          <li>Created a web video calling feature</li>
        </ul>
        <div className={styles.Tech}>
          <div>TypeScript</div>
          <div>React</div>
          <div>Node.js</div>
          <div>Google Cloud Platform</div>
          <div>Firebase</div>
          <div>Agora</div>
        </div>

        <div className={styles.Year}>2021</div>

        <h2>Senior Software Engineer at Toptal (Freelance)</h2>
        <h3>April 2020 - April 2021</h3>
        <div>Beeyou.tv</div>
        <ul>
          <li>Implemented payments and money withdrawals using Stripe API</li>
          <li>Created admin dashboards</li>
          <li>Created a user settings form</li>
          <li>Updated the user onboarding process</li>
        </ul>
        <div className={styles.Tech}>
          <div>JavaScript</div>
          <div>React</div>
          <div>Node.js</div>
          <div>MongoDB</div>
          <div>AWS</div>
          <div>Lambda</div>
          <div>Mux</div>
        </div>

        <br />

        <div>Payersciences.com</div>
        <ul>
          <li>Created a reporting app using SVG graphics</li>
          <li>
            Created a form generator that collected data for report generation
          </li>
          <li>Automatized PDF and Excel report generation</li>
        </ul>
        <div className={styles.Tech}>
          <div>TypeScript</div>
          <div>React</div>
          <div>Node.js</div>
          <div>Express</div>
          <div>GraphQL</div>
          <div>Nest.js</div>
          <div>MongoDB</div>
          <div>PostgreSQL</div>
          <div>Azure</div>
          <div>Docker</div>
          <div>Kubernetes</div>
        </div>

        <div className={styles.Year}>2020</div>

        <h2>Senior Software Engineer at Xapo (xapo.com)</h2>
        <h3>August 2018 - April 2020</h3>
        <ul>
          <li>
            Created a web service for local third party processors integration
          </li>
          <li>Created a dashboard to manage transactions</li>
          <li>Created a dynamic checkout form</li>
        </ul>
        <div className={styles.Tech}>
          <div>Python</div>
          <div>Flask</div>
          <div>React</div>
          <div>Redux</div>
          <div>Next.js</div>
          <div>Node.js</div>
          <div>MySQL</div>
          <div>AWS</div>
          <div>SQS</div>
          <div>SNS</div>
          <div>Docker</div>
          <div>Microservices</div>
        </div>

        <h2>Software Engineer at Toptal (Freelance)</h2>
        <h3>February 2018 - August 2018</h3>
        <ul>
          <li>Created a marketplace and business networking app</li>
          <li>
            Worked on the frontend development of a Cryptocurrency exchange
            platform (coti.io)
          </li>
        </ul>
        <div className={styles.Tech}>
          <div>JavaScript</div>
          <div>React</div>
          <div>Node.js</div>
          <div>Express</div>
          <div>Redux</div>
          <div>Redux-saga</div>
          <div>PostgreSQL</div>
          <div>AWS</div>
        </div>

        <div className={styles.Year}>2018</div>

        <h2>Software Engineer at Wizeline (wizeline.com)</h2>
        <h3>July 2017 - February 2018</h3>
        <ul>
          <li>
            Created Alexa and Google home skills for a recipe and cooking app
            (taste.com.au)
          </li>
          <li>Created dashboard panels for different apps</li>
        </ul>
        <div className={styles.Tech}>
          <div>JavaScript</div>
          <div>React</div>
          <div>Node.js</div>
          <div>Express</div>
          <div>Redux</div>
          <div>AWS</div>
          <div>Redis</div>
          <div>Amazon Alexa</div>
          <div>Google Home</div>
        </div>

        <div className={styles.Year}>2017</div>

        <h2>Software Engineer at Nearsoft</h2>
        <h3>September 2015 - July 2017</h3>

        <div>Opentable.com</div>
        <ul>
          <li>
            Created new features for the Booking microservice using AB testing
            to measure feature performance.
          </li>
        </ul>
        <div className={styles.Tech}>
          <div>JavaScript</div>
          <div>Node.js</div>
          <div>Optimizely</div>
          <div>Redis</div>
          <div>Microservices</div>
        </div>

        <div>pHin.co</div>
        <ul>
          <li>
            Created admin dashboards for a smart home solution for pools and a
            pool service network
          </li>
        </ul>
        <div className={styles.Tech}>
          <div>JavaScript</div>
          <div>Node.js</div>
          <div>Express</div>
          <div>AngularJS</div>
          <div>MongoDB</div>
        </div>

        <h2>Software Engineer at NextCloud (next-cloud.mx)</h2>
        <h3>February 2015 - September 2015</h3>
        <ul>
          <li>Created an E-commerce module for an ERP</li>
        </ul>
        <div className={styles.Tech}>
          <div>JavaScript</div>
          <div>Node.js</div>
          <div>AngularJS</div>
          <div>Redis</div>
          <div>Apache Solr</div>
        </div>

        <div className={styles.Year}>2015</div>

        <h2>Software Developer at SEDUVI</h2>
        <h3>February 2012 - February 2015</h3>
        <ul>
          <li>Created multiple apps related to urban development</li>
          <li>Linux server management</li>
        </ul>
        <div className={styles.Tech}>
          <div>PHP</div>
          <div>MySQL</div>
          <div>AngularJS</div>
        </div>

        <div className={styles.Year}>2012</div>

        <h2>Freelance Web Development</h2>
        <h3>August 2009 - February 2012</h3>
        <ul>
          <li>Webapp and website development for local small businesses</li>
        </ul>
        <div className={styles.Tech}>
          <div>PHP</div>
          <div>MySQL</div>
        </div>

        <div className={styles.Year}>2009</div>
      </div>
    </div>
  )
}

export default Work
