import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import styles from './Contact.module.css'

const Contact: React.FC = () => {
  return (
    <div id='contact'>
      <h1>Contact</h1>

      <div className={styles.Contacts}>
        <a href='https://github.com/artdomg' target='_blank' rel='noreferrer'>
          <FontAwesomeIcon icon={faGithub} />
        </a>

        <a
          href='https://www.linkedin.com/in/arturodominguezg/'
          target='_blank'
          rel='noreferrer'
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
    </div>
  )
}

export default Contact
