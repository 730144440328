import React from 'react'
import About from './About'
import Contact from './Contact'
import Header from './Header'
import Work from './Work'

import styles from './Home.module.css'

const Home: React.FC = () => {
  return (
    <div className={styles.Home}>
      <Header />
      <About />
      <Work />
      <Contact />
    </div>
  )
}

export default Home
