import React from 'react'

import styles from './Header.module.css'

const Header: React.FC = () => {
  return (
    <header className={styles.Header}>
      <div className={styles.Logo}>AD</div>
      <div className={styles.Menu}>
        <div>
          <a href='#about'>About</a>
        </div>
        <div>
          <a href='#work'>Work</a>
        </div>
        <div>
          <a href='#contact'>Contact</a>
        </div>
      </div>
    </header>
  )
}

export default Header
